import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { Container, Content, Tab, Tabs, Title } from '../../../App';
import { TABS, URLS } from '../../../constants';
import Button from '../../../components/Button';
import { useSupplier } from '../../../context/SupplierContext';
import { Request } from '../../../interfaces';
import RequestForm from '../AddRequest/RequestForm';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../components/Modal';
import SendToSupplier from '../SendToSupplierModal';
import { applicationsColumns } from '../config';
import Table from '../../../components/Table';
import { applications } from '../../../mocks';

const RequestDetailsView: React.FC = () => {
    
    const location = useLocation();
    const state = location.state;

    const { requestId } = state;
    const { requests, setRequests } = useSupplier();

    const reqIndex = requests.findIndex((req) => req.id === requestId) || 0
    const request = requests[reqIndex]

    const [newRequest, setNewRequest] = useState(request);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const updateRequest = (newReq: Request) => {
        setNewRequest(newReq)
    }

    const navigate = useNavigate();

    const [isEdit, setIsEdit] = useState(false);

    const save = () => {
        setIsEdit(false)
        requests[reqIndex] = newRequest
        setRequests([...requests])
        setModalIsOpen(true)
    }

    const handleClickOnRow = (application: Request) => {
        navigate(URLS.applicationDetails, { state: { appId: application.id } });
      };

    const [activeTab, setActiveTab] = useState('details');

    const tabConfig = [
        {
          label: "Contract details",
          key: "details",
          component:
            <>
                <RequestForm
                        setNewRequest={updateRequest}
                        newRequest={newRequest}
                        isEditModeOn={isEdit}
                    />
                { isEdit
                    ? <Button onClick={save}>Save</Button>
                    : <div style={{ display: 'flex', gap: '20px'}}>
                        <Button onClick={() => setIsEdit(true)}>Edit request</Button>
                        <Button onClick={() => navigate(URLS.requests)}>Close</Button>
                      </div>
                }
            </>
        },
        {
            label: 'Applications',
            key: 'applications',
            component:
                <>
                    <Table columns={applicationsColumns} rowData={applications} height={300} onRowClick={(row) => handleClickOnRow(row.data)}/>
                </>
        },
        {
            label: 'Documents',
            key: 'documents',
            component:
                <>
                   
                </>
        },
        {
            label: 'Activity log',
            key: 'activityLog',
            component:
                <>
                   
                </>
        }
    ]

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.requests} />
                
                <Content>
                    <Title>Request {requestId}</Title>
                    
                    
                    <Tabs>
                        {tabConfig.map((tabItem, index) => (
                            <Tab
                                key={tabItem.key}
                                onClick={() => setActiveTab(tabItem.key)}
                                active={activeTab === tabItem.key}
                                tabIndex={index}
                                role="button"
                            >
                                {tabItem.label}
                            </Tab>
                        ))}
                    </Tabs>
                    
                    { tabConfig.find(({ key }) => key === activeTab)?.component }

          
                </Content>
            </Container>

            <Modal
                isOpen={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
            >
                    <SendToSupplier cancel={() => setModalIsOpen(false)} />
            </Modal>
            
        </>
    );
};

export default RequestDetailsView;
