export const contracts = [
  {
      id: 43211,
      title: "Office Supplies Agreement",
      reference: "OSA-2024",
      type: "Procurement",
      supplierId: 46782,
      country: "United States",
      department: "Operations",
      startDate: "2024-01-01",
      endDate: "2025-01-01",
      noticePeriodDate: "2024-12-01",
      currency: "USD",
      estimatedValue: "50000",
      items: [
        {
            id: 1,
            productCategory: "Packaging Materials",
            material: "Recycled Cardboard",
            ethicalStandard: "Fair Trade Certified",
            capacityRange: "10-20 kg",
            certifications: "ISO 9001, FSC Certified",
            quantity: 500,
            unitPrice: 2,
            details: "Corrugated cardboard boxes with custom printing.",
            subtotal: 1000, // Calculated as quantity * unitPrice
        },
        {
            id: 2,
            productCategory: "Packaging Materials",
            material: "Recycled Cardboard",
            ethicalStandard: "Fair Trade Certified",
            capacityRange: "10-20 kg",
            certifications: "ISO 9001, FSC Certified",
            quantity: 100,
            unitPrice: 2.5,
            details: "Corrugated cardboard boxes with custom printing.",
            subtotal: 250, // Calculated as quantity * unitPrice
        }
      ],
    status: 'open'
    
  },
  {
      id: 21249,
      title: "IT Services Contract",
      reference: "ITSC-2024",
      type: "Service",
      supplierId: 32274,
      country: "India",
      department: "IT",
      startDate: "2024-03-15",
      endDate: "2025-03-15",
      noticePeriodDate: "2024-12-01",
      currency: "INR",
      estimatedValue: "2000000",
      items: [],
      status: 'closed'
  },
  {
      id: 44443,
      title: "Cleaning Services Contract",
      reference: "CSC-2024",
      type: "Service",
      supplierId: 36479,
      country: "Canada",
      department: "Operations",
      startDate: "2024-02-01",
      endDate: "2024-12-31",
      noticePeriodDate: "2024-12-01",
      currency: "CAD",
      estimatedValue: "30000",
      items: [],
      status: 'open'

  },
  {
      id: 34344,
      title: "Marketing Consultancy",
      reference: "MC-2024",
      type: "Consultancy",
      supplierId: 46556,
      country: "United Kingdom",
      department: "Marketing",
      startDate: "2024-04-01",
      endDate: "2024-10-01",
      noticePeriodDate: "2024-12-01",
      currency: "GBP",
      estimatedValue: "15000",
      items: [],
      status: 'open'
  }
]
