import styled from "styled-components";
import { colors, inputBorderRadius } from "../../../constants";

export const FilterSummary = styled.div`
  font-size: 14px;
  width: 170px;
  font-weight: 600;
  color: ${colors.darkGreen};
  span {
    color: ${colors.mediumGreen};
  }
`;

export const FiltersHeader = styled.div`
  display: flex;
`;

export const FilterButton = styled.button`
  background-color: ${colors.darkGreen};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    background-color: ${colors.mediumGreen};
  }
`;

export const SidebarHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 18px;
    color: ${colors.darkGreen};
    display: flex;
    align-items: center;
    gap: 5px;
  }

  > svg {
    cursor: pointer;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  left: -30px;
  background-color: ${colors.lightGrey};
  width: 30px;
  height: 60px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  font-weight: 500;
`;

export const Divisor = styled.div`
  width: 2px;
  height: 35px;
  margin: 0 20px;
  background-color: ${colors.darkOrange};
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  margin-bottom: 20px;
  > div {
    width: 200px;
    margin-bottom: 0px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
`;

export const SupplierCard = styled.div`
  background-color: ${colors.lightGrey};
  border-radius: 8px;
  width: 340px;
  flex-grow: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.2s;
  border-radius: 20px;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const CardTitle = styled.h3`
  font-size: 18px;
  padding: 10px 20px;
  margin-right: 50px;
  color: white;
  margin-bottom: 10px;
  background-color: ${colors.darkGreen};
  border-bottom-right-radius: 20px;
`;

export const CardInfo = styled.p`
  margin: 5px 0;
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const CardContent = styled.div`
  font-size: 14px;
  padding: 10px 20px 20px 20px;
  color: ${colors.darkGreen};
`;

export const CardButton = styled.div`
  color: ${colors.darkGreen};
  font-size: 14px;
  margin: 15px;
  padding: 5px 10px;
  border-radius: 15px;
  width: fit-content;
  margin-top: auto;
  border: 1px solid ${colors.darkGreen};
  align-self: flex-end;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;

export const PaginationButton = styled.button`
  padding: 8px 16px;
  border: 1px solid ${colors.darkGreen};
  background-color: ${colors.lightGrey};
  color: ${colors.darkGreen};
  cursor: pointer;
  border-radius: 4px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${colors.darkGreen};
    color: white;
  }
`;

export const PageInfo = styled.div`
  font-size: 14px;
  color: ${colors.darkGreen};
`;

export const RoundButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  position: fixed;
  bottom: 40px;
  right: 0px;
  background-color: #ffffff93;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  
  &:hover {
    background-color: #fff;
  }
`;

export const SidebarContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? '0' : '-340px')};
  width: 300px;
  height: 100%;
  background-color: ${colors.lightGrey};
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 10;
  display: flex;
  flex-direction: column;
  > div {
    overflow-y: scroll;
    padding: 20px;
  }
`;


export const SearchBarWrapper = styled.div`
  margin-bottom: 20px;  
`;

export  const SidebarLabel = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: ${colors.darkGreen};
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
`
export const SearchInput = styled.input`
    padding: 10px;
    font-size: 14px;
    width: 100%;
    border: 1px solid ${colors.mediumGreen};
    border-radius: ${inputBorderRadius};

    &:focus {
        border: 1px solid ${colors.mediumGreen};

    }
`;

export const CalendarContainerWrapper = styled.div`
  .react-datepicker {
    border: none;
    font-family: 'Poppins', sans-serif;
    color: white;
    display: block;
    background-color: ${colors.darkGreen};
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

  }

  .react-datepicker__header,
  .react-datepicker__day-names {
    background-color: ${colors.darkGreen};

    border-bottom: none;
  }

  .react-datepicker__month {
    padding-bottom: 10px;
  }

  .react-datepicker__current-month {
    margin-bottom: 10px;
    color: ${colors.darkGreen};
    font-weight: 400;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before{
    border-color: ${colors.darkOrange};
  }

  .react-datepicker__day-names {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-top: 5px;

  }

  .react-datepicker__header  {
    background-color: ${colors.lightGrey};
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: white;
  }

  .react-datepicker__day--selected {
    color: ${colors.darkGreen};
    background-color: white;
    border-radius: 50%;
  }

  .react-datepicker__day:not([aria-disabled=true]):hover,
  .react-datepicker__month-text:not([aria-disabled=true]):hover,
  .react-datepicker__quarter-text:not([aria-disabled=true]):hover,
  .react-datepicker__year-text:not([aria-disabled=true]):hover {
    border-radius: 50%;
    color: ${colors.darkGreen};
    background-color: white;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 50%;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: ${colors.darkGreenHover};
    border-radius: 50%;
  }
`;

export const Date = styled.div`
    background-color: ${colors.grey};
    border-radius: 10px;
    padding: 4px 10px;
    color: ${colors.darkGreen};
    font-size: 13px;
    font-weight: 600;
`

export const Dates = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`;