import React, { useState } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker'; // Install using npm install react-datepicker
import 'react-datepicker/dist/react-datepicker.css';
import Sidebar from '../../../components/Sidebar';
import { colors, TABS, URLS } from '../../../constants';
import Header from '../../../components/Header';
import { Supplier } from '../../../interfaces';
import { useNavigate } from 'react-router-dom';
import { Container, Content } from '../../../App';
import RoundButton from '../../../components/RoundButton';
import { useSupplier } from '../../../context/SupplierContext';
import { certificationsMock, countries_, industries, productCategories } from '../../../mocks';
import { LuPackage } from "react-icons/lu";
import { FaGlobe } from "react-icons/fa";
import { FaIndustry } from "react-icons/fa6";
import { MdArrowForwardIos } from "react-icons/md";
import { IoPersonCircleOutline } from "react-icons/io5";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { IoRefreshCircleOutline } from "react-icons/io5";

import {
  CalendarContainerWrapper,
  CardButton,
  CardContainer,
  CardContent,
  CardInfo,
  CardTitle,
  CloseButton,
  Date,
  Dates,
  Divisor,
  FilterButton,
  FiltersContainer,
  FiltersHeader,
  FilterSummary,
  PageInfo,
  Pagination,
  PaginationButton,
  RoundButtonWrapper,
  SearchBarWrapper,
  SearchInput,
  SidebarContainer,
  SidebarHeader,
  SidebarLabel,
  SupplierCard
} from './styles';
import { RiMenu5Fill } from "react-icons/ri";
import MultiSelect from '../../../components/Multiselect';
import { MdAvTimer } from "react-icons/md";
import Button from '../../../components/Button';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const ITEMS_PER_PAGE = 30;

const FindSupplier: React.FC = () => {
  const [countryFilter, setCountryFilter] = useState<string[]>([]);
  const [industryFilter, setIndustryFilter] = useState<string[]>([]);
  const [productCategoryFilter, setProductCategoryFilter] = useState<string[]>([]); 
  const [supplierFilter, setSupplierFilter] = useState<string[]>([]); 
  const [certificationFilter, setCertificationFilter] = useState<string[]>([]); 

  const [isSidebarOpen, setIsSidebarOpen] = useState(false); 
  const [startDate, setStartDate] = useState<Date | null>(null); // State for the date picker
  const [endDate, setEndDate] = useState<Date | null>(null); // State for the date picker

  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { suppliers } = useSupplier();

  const [searchTerm, setSearchTerm] = useState('');

  const selectedCountries = countryFilter.map(country => countries_.find(({ value }) => value === country)?.label);
  const selectedIndustries = industryFilter.map(ind => industries.find(({ value }) => value === ind)?.label);
  const selectedProductCategories = productCategoryFilter.map(prodCat => productCategories.find(({ value }) => value === prodCat)?.label);
  const selectedCertifications = certificationFilter.map(cert => certificationsMock.find(({ value }) => value === cert)?.label);

  const filteredSuppliers = suppliers?.filter(supplier => {
    const matchesCountry = selectedCountries.length < 1 || selectedCountries.includes(supplier.country);
    const matchesIndustry = selectedIndustries.length < 1 || selectedIndustries.includes(supplier.industry);
    const mathesProductCategories = selectedProductCategories.length < 1 || selectedProductCategories.some(pc => pc && supplier.product_categories?.split(', ').includes(pc))
    const matchesSupplier = supplierFilter.length < 1 || supplierFilter.includes(supplier.id.toString())
    const matchesCertifications = certificationFilter.length < 1 || selectedCertifications.some(cert => cert && supplier.certifications?.split(', ').includes(cert))

    return matchesCountry && matchesIndustry && mathesProductCategories && matchesSupplier && matchesCertifications;
  })
  .filter(obj =>
    Object.values(obj).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  )

  const totalPages = Math.ceil((filteredSuppliers?.length || 0) / ITEMS_PER_PAGE);
  const paginatedSuppliers = filteredSuppliers?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClickOnCard = (supplier: Supplier) => {
    navigate(URLS.supplierDetails, { state: { supplierId: supplier.id } });
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const onChangeDates = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const resetFilters = () => {
    setCountryFilter([])
    setIndustryFilter([])
    setProductCategoryFilter([])
    setSupplierFilter([])
    setCertificationFilter([])
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <>
      <Header />
      <Container>
        <Sidebar tab={TABS.suppliers} />

        <Content>
        <SearchBarWrapper>
          <SearchInput
            type="text"
            placeholder="Search suppliers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          </SearchBarWrapper>

          <FiltersHeader>   
            <FiltersContainer>
              <MultiSelect
                options={countries_}
                name="country"
                selectedValues={countryFilter}
                onChange={values => {
                  setCountryFilter(values);
                  setCurrentPage(1);
                }}
                placeholder='All locations' 
              />
              <MultiSelect
                options={industries}
                name="industry"
                selectedValues={industryFilter}
                onChange={values => {
                  setIndustryFilter(values);
                  setCurrentPage(1);
                }}
                placeholder='All industries' 
              />
              <MultiSelect
                options={productCategories}
                name="productCategories"
                selectedValues={productCategoryFilter}
                onChange={values => {
                  setProductCategoryFilter(values);
                  setCurrentPage(1);
                }}
                placeholder='All product categories' 
              />
              <FilterButton onClick={handleSidebarToggle}>
                <RiMenu5Fill size={20} />
              </FilterButton>
            </FiltersContainer>

            <Divisor />

            <FilterSummary>
              Showing <span>{filteredSuppliers.length}</span> suppliers <br />
              {!!countryFilter.length && <> in <span>{selectedCountries.join(', ')}</span></>}
            </FilterSummary>
          </FiltersHeader>

          <CardContainer>
            {paginatedSuppliers?.map(supplier => (
              <SupplierCard key={supplier.id} onClick={() => handleClickOnCard(supplier)}>
                <CardTitle>{supplier.company}</CardTitle>
                <CardContent>
                  <CardInfo><FaGlobe color={colors.darkOrange} /> {supplier.country}</CardInfo>
                  { supplier.product_categories && <CardInfo><LuPackage color={colors.darkOrange} /> {supplier.product_categories}</CardInfo> }
                  { supplier.industry && <CardInfo><FaIndustry color={colors.darkOrange} /> {supplier.industry}</CardInfo>}
                  { supplier.certifications && <CardInfo><AiOutlineSafetyCertificate color={colors.darkOrange} /> {supplier.certifications}</CardInfo>}
                </CardContent>
                <CardButton>Contact</CardButton>
              </SupplierCard>
            ))}
          </CardContainer>

          <Pagination>
            <PaginationButton
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </PaginationButton>
            <PageInfo>
              Page {currentPage} of {totalPages}
            </PageInfo>
            <PaginationButton
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </PaginationButton>
          </Pagination>

          <RoundButtonWrapper>
            <RoundButton onClick={() => navigate(URLS.addSupplier)} text="Add new supplier" size={60} />
          </RoundButtonWrapper>
        </Content>

        <SidebarContainer isOpen={isSidebarOpen}>
          <div>
            <CloseButton onClick={handleSidebarToggle}>
              <MdArrowForwardIos color={colors.darkOrange} />
            </CloseButton>
            <SidebarHeader>
              <h3><RiMenu5Fill size={17} />Filters</h3>
              <IoRefreshCircleOutline onClick={resetFilters} size={30} color={colors.darkGreen} />
            </SidebarHeader>
            <SidebarLabel>
              <FaGlobe color={colors.darkOrange}/>
              <div>Location</div>
            </SidebarLabel>
            <MultiSelect
              options={countries_}
              name="country"
              selectedValues={countryFilter}
              onChange={values => {
                setCountryFilter(values);
                setCurrentPage(1);
              }}
              placeholder='All locations' 
            />

            <SidebarLabel>
              <FaIndustry color={colors.darkOrange}/>
              <div>Industry</div>
            </SidebarLabel>
            <MultiSelect
              options={industries}
              name="industry"
              selectedValues={industryFilter}
              onChange={values => {
                setIndustryFilter(values);
                setCurrentPage(1);
              }}
              placeholder='All industries' 
            />
            <SidebarLabel>
              <LuPackage color={colors.darkOrange} />
              <div>Product category</div>
            </SidebarLabel>
            <MultiSelect
              options={productCategories}
              name="productCategories"
              selectedValues={productCategoryFilter}
              onChange={values => {
                setProductCategoryFilter(values);
                setCurrentPage(1);
              }}
              placeholder='All product categories' 
            />
            <SidebarLabel>
              <IoPersonCircleOutline color={colors.darkOrange} />
              <div>Supplier</div>
            </SidebarLabel>
            <MultiSelect
              options={suppliers.map((sup) => { return { value: sup.id.toString(), label: sup.company }})}
              name="suppliers"
              selectedValues={supplierFilter}
              onChange={values => {
                setSupplierFilter(values);
                setCurrentPage(1);
              }}
              placeholder='All suppliers' 
            />
            <SidebarLabel>
              <AiOutlineSafetyCertificate color={colors.darkOrange} />
              <div>Certification</div>
            </SidebarLabel>
            <MultiSelect
              options={certificationsMock}
              name="certification"
              selectedValues={certificationFilter}
              onChange={values => {
                setCertificationFilter(values);
                setCurrentPage(1);
              }}
              placeholder='All certifications' 
            />
            <CalendarContainerWrapper>
              <CalendarContainer>
                <SidebarLabel>
                  <MdAvTimer color={colors.darkOrange } />
                  Certification expiry date range
                </SidebarLabel>
                <DatePicker
                  id="date-picker"
                  onChange={(dates) => onChangeDates(dates)}
                  placeholderText="Pick a date"
                  className="custom-date-picker"
                  startDate={startDate === null ? undefined : startDate}
                  endDate={endDate === null ? undefined : endDate}
                  selectsRange
                  inline
                />
              </CalendarContainer>

              <Dates>
                { startDate && <Date>{startDate?.toLocaleDateString()}</Date> }
                { startDate && endDate && <MdKeyboardDoubleArrowRight color={colors.darkOrange} /> }
                { endDate && <Date>{endDate?.toLocaleDateString()}</Date> }
              </Dates>  
            </CalendarContainerWrapper>
            
            <div style={{ width: 100, margin: '20px auto'}}>
              <Button>Apply</Button>
            </div>
          </div>
        </SidebarContainer>
      </Container>
    </>
  );
};

export default FindSupplier;
