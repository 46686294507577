import React, { useState } from 'react';
import { AddItemContainer, Flex, GeneralInfo, InputWrapper, Label, TableBox } from '../../styles';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import MultiSelect from '../../../../components/Multiselect';
import RoundButton from '../../../../components/RoundButton';
import Select from '../../../../components/Select';
import Table from '../../../../components/Table';
import { industries, countries_, currencies, productCategories, certificationsMock } from '../../../../mocks';
import { requestItemsColumns } from '../../config';
import { Item } from '../../../../interfaces';
import { randomId } from '../../../../utils';
import { useSupplier } from '../../../../context/SupplierContext';
import { Request } from '../../../../interfaces'
import Checkbox from '../../../../components/Checkbox';

interface FormProps {
    newRequest: Request;
    setNewRequest: (req: Request) => void;
    isEditModeOn: boolean;
}
const RequestForm: React.FC<FormProps> = ({ newRequest, setNewRequest, isEditModeOn }) => {
    const itemDefault = {
        id: 0,
        productCategory: '',
        material: '',
        ethicalStandard: '',
        capacityRange: '',
        certifications: '',
        quantity: 0,
        details: '',
        unitPrice: 0,
        subtotal: 0
    }

    const { departments } = useSupplier();

    const handleInputChange = (field: string, value: string | number | boolean) => {
        setNewRequest({ ...newRequest, [field]: value });
    };

    const [addItemMode, setAddItemMode] = useState(false);

    const [newItem, setNewItem] = useState<Item>(itemDefault)

    const saveItem = () => {
        const newId = randomId()
        const items = [...newRequest.items, { ...newItem, id: newId, subtotal: newItem.unitPrice * newItem.quantity }]
        setNewRequest({ ...newRequest, items })
        setNewItem(itemDefault)
        setAddItemMode(false)
    }

    const cancelItem = () => {
        setAddItemMode(false);
        setNewItem(itemDefault)
    }

    const handleInputItemChange = (field: string, value: string | number) => {
        setNewItem({ ...newItem, [field]: value });
    };

    return (
        <>
           <div style={{ display: 'flex', gap: '20px' }}>
                <TableBox>
                    <InputWrapper>
                        <label htmlFor='name'>Request Name</label>
                        <Input
                            value={newRequest.name}
                            type='text'
                            name='name'
                            onChange={(value) => handleInputChange('name', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='industry'>Industry</label>

                        <Select
                            options={industries}
                            name="industry"
                            value={newRequest.industry || ''}
                            onChange={e => {
                                handleInputChange('industry', e.target.value);
                            }}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='startDate'>Start Date</label>
                        <Input
                            value={newRequest.startDate}
                            type='date'
                            name='startDate'
                            onChange={(value) => handleInputChange('startDate', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='endDate'>End Date</label>
                        <Input
                            value={newRequest.dueDate}
                            type='date'
                            name='endDate'
                            onChange={(value) => handleInputChange('dueDate', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='location'>Location</label>

                        <Select
                            options={countries_}
                            name="location"
                            value={newRequest.location || ''}
                            onChange={e => {
                                handleInputChange('location', e.target.value);
                            }}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='departmenet'>Department</label>

                        <Select
                            options={departments.map(dep => { return { value: dep.name, label: dep.name } })}
                            name="departmenet"
                            value={newRequest.department || ''}
                            onChange={e => {
                                handleInputChange('departmenet', e.target.value);
                            }}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>
                </TableBox>

                <TableBox>
                    <InputWrapper>
                        <label htmlFor='budget'>Budget</label>
                        <Input
                            value={newRequest.budget}
                            type='text'
                            name='budget'
                            onChange={(value) => handleInputChange('budget', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <label htmlFor='currency'>Currency</label>

                        <Select
                            options={currencies}
                            name="currency"
                            value={newRequest.currency || ''}
                            onChange={e => {
                                handleInputChange('currency', e.target.value);
                            }}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>
                </TableBox>
            </div>

                    
            <div style={{ marginTop: '20px' }}>
                <Label>Request items</Label>
                <div style={{ paddingBottom: '20px'}}>
                    <Table rowData={newRequest.items || []} columns={requestItemsColumns} height={300} />
                </div>
                { !addItemMode && isEditModeOn && 
                    <AddItemContainer>
                        <RoundButton onClick={() => setAddItemMode(true)} text="Add item" /> 
                    </AddItemContainer>}
            </div>
                
        {addItemMode && isEditModeOn && 
    
    <div style={{ marginTop: '20px' }}>
        <TableBox>
            <Label>New item</Label>
            <Flex>
                <GeneralInfo>
                    <InputWrapper>
                        <label htmlFor='productCategory'>Product Category</label>
                        <Select
                            value={newItem.productCategory}
                            options={productCategories}
                            name='productCategory'
                            onChange={(e) => handleInputItemChange('productCategory', e.target.value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='material'>Material used</label>
                        <MultiSelect
                            selectedValues={newItem.material ? newItem.material.split(',') : []}
                            options={[ { label: 'material1', value: 'material1' }]}
                            name='material'
                            onChange={(values) => handleInputItemChange('material', values.join(','))}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='ethicalStandard'>Ethical standard</label>
                        <MultiSelect
                            selectedValues={newItem.ethicalStandard ? newItem.ethicalStandard.split(',') : []}
                            options={[ { label: 'standard1', value: 'standard1' }]}
                            name='ethicalStandard'
                            onChange={(values) => handleInputItemChange('ethicalStandard', values.join(','))}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='capacityRange'>Capacity range</label>
                        <Select
                            value={newItem.capacityRange}
                            options={[ { label: 'range1', value: 'range1' }]}
                            name='capacityRange'
                            onChange={(e) => handleInputItemChange('capacityRange', e.target.value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='certifications'>Certifications</label>
                        <MultiSelect
                            name="certifications"
                            selectedValues={newItem.certifications ? newItem.certifications.split(',') : []}
                            options={certificationsMock}
                            onChange={(values) => handleInputItemChange('certifications', values.join(','))}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>
                    </GeneralInfo>
                    
                    <GeneralInfo>
                    <InputWrapper>
                        <label htmlFor='quantity'>Quantity</label>
                        <Input
                            value={newItem.quantity}
                            type="number"
                            name='quantity'
                            onChange={(value) => handleInputItemChange('quantity', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='unitPrice'>Unit price</label>
                        <Input
                            value={newItem.unitPrice}
                            type="number"
                            name='unitPrice'
                            onChange={(value) => handleInputItemChange('unitPrice', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='details'>Details</label>
                        <Input
                            textarea
                            value={newItem.details}
                            name='details'
                            onChange={(value) => handleInputItemChange('details', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    </GeneralInfo>
                    </Flex>

                    <div style={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                        <Button onClick={saveItem} width='100px'>Save item</Button>
                        <Button onClick={cancelItem} width='100px'>Cancel</Button>
                    </div>
                </TableBox>
                </div>}

                <div style={{ marginTop: '20px' }}>
                    <Label>Description</Label>
                    <InputWrapper>
                        <Input
                            value={newRequest.details}
                            textarea
                            name='details'
                            onChange={(value) => handleInputChange('details', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>
                </div> 

                <TableBox style={{ marginTop: '20px' }}>
                    <Label>Required documents</Label>
                        <Checkbox
                        checked={!!newRequest.shippingDetailsRequired}
                        name="Shipping details"
                        label="Shipping details"
                        onChange={(value) => handleInputChange('shippingDetailsRequired', value)}
                        labelStyle={{ fontWeight: 400, color: 'black', fontSize: 14 }}
                        style={{ margin: 0 }}
                        disabled={!isEditModeOn}
                    />
                    <Checkbox
                        checked={!!newRequest.qualityCertificateRequired}
                        name="qualityCertificate"
                        label="Quality Certificate"
                        onChange={(value) => handleInputChange('qualityCertificateRequired', value)}
                        labelStyle={{ fontWeight: 400, color: 'black', fontSize: 14  }}
                        style={{ margin: 0 }}
                        disabled={!isEditModeOn}
                    />
                    <Checkbox
                        checked={!!newRequest.packingListRequired}
                        name="packingListRequired"
                        label="Packing List"
                        onChange={(value) => handleInputChange('packingListRequired', value)}
                        labelStyle={{ fontWeight: 400, color: 'black', fontSize: 14  }}
                        style={{ margin: 0 }}
                        disabled={!isEditModeOn}
                    />
                    <Checkbox
                        checked={!!newRequest.tradingLicenseRequired}
                        name="tradingLicenseRequired"
                        label="Trading License"
                        onChange={(value) => handleInputChange('tradingLicenseRequired', value)}
                        labelStyle={{ fontWeight: 400, color: 'black', fontSize: 14  }}
                        style={{ margin: 0 }}
                        disabled={!isEditModeOn}
                    />

                    <div style={{ paddingTop: '30px', width: '400px' }}>
                    <Label>Attach supporting documents</Label>
                        <Input
                            type="file"
                            multiple
                            name="documents"
                
                            disabled={!isEditModeOn}
                        />  
                    </div>
                </TableBox>
        </>
    )
}

export default RequestForm;