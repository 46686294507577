export const requestItemsColumns = [
    {
        field: 'id',
        filter: true,
    },
    {
        field: 'productCategory',
        filter: true,
    },
    {
        field: 'material',
        filter: true,
    },
    {
        field: 'capacityRange',
        filter: true,
    }
]

export const applicationsColumns = [
    {
        field: 'id',
        filter: true,
    },
    {
        field: 'supplierName',
        filter: true,
    },
    {
        field: 'status',
        filter: true,
    },
    {
        field: 'reminder',
        filter: true,
    },
    
]
