import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../constants';
import { Label } from '../../styles';

const ProgressBarContainer = styled.div`
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  width: 100%;
  height: 20px;
  position: relative;
`;

const Progress = styled.div<{ progress: number }>`
  background-color: ${colors.darkGreen};
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  border-radius: 5px;
  /* transition: width 0.3s ease-in-out; */
`;

interface ProgressBarProps {
  isActive: boolean;
  duration?: number; // Total duration in milliseconds
}

const ProgressBar: React.FC<ProgressBarProps> = () => {
  return (
    <>
      <Label>Contract progress chart</Label>
      <ProgressBarContainer>
      <Progress progress={50} />
    </ProgressBarContainer></>
  );
};

export default ProgressBar;
