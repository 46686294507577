import { suppliers } from "./suppliers";
import { users } from "./users";
import { contracts } from "./contracts";
import { Request } from "../interfaces";

export const languages = [{ value: 'english', label: 'English' }, { value: 'spanish', label: 'Spanish' }, { value: 'french', label: 'French' }];

export const applications = [
    {
        id: 1,
        supplierName: 'Supplier1',
        status: 'pending',
        reminder: '04/01/2024'
    }
]

export const departmentsMock = [
    {
        name: 'Human Resources',
        details: 'Responsible for recruitment, employee relations, and workforce management.',
    },
    {
        name: 'Finance',
        details: 'Manages the company’s financial planning, budgeting, and accounting.',
    },
    {
        name: 'Operations',
        details: 'Oversees day-to-day operations and ensures efficient processes.',
    },
    {
        name: 'Sales',
        details: 'Handles client acquisition, relationship management, and revenue growth.',
    },
    {
        name: 'Marketing',
        details: 'Focuses on brand management, advertising, and market research.',
    },
    {
        name: 'IT',
        details: 'Provides technology support and infrastructure management.',
    },
    {
        name: 'Legal',
        details: 'Ensures compliance with regulations and provides legal guidance.',
    },
    {
        name: 'Research and Development',
        details: 'Responsible for product innovation and development.',
    },
];
export const certificationsMock = [
    {
        value: "fair-trade-certified",
        label: "Fair Trade Certified"
    },
    {
        value: "bluesign-approved",
        label: "Bluesign Approved"
    },
    {
        value: "iso-14001-certified",
        label: "ISO 14001 Certified"
    },
    {
        value: "carbon-neutral",
        label: "Carbon Neutral"
    },
    {
        value: "renewable-energy-certificates",
        label: "Renewable Energy Certificates (REC)"
    },
    {
        value: "green-technology-certification",
        label: "Green Technology Certification"
    },
    {
        value: "recycled-content-certification",
        label: "Recycled Content Certification"
    },
    {
        value: "b-corp-certified",
        label: "B-Corp Certified"
    },
    {
        value: "gots-certified",
        label: "GOTS Certified"
    },
    {
        value: "leed-certified",
        label: "LEED Certified"
    },
    {
        value: "green-tech-approved",
        label: "Green Tech Approved"
    },
    {
        value: "forest-stewardship-council-certified",
        label: "Forest Stewardship Council (FSC) Certified"
    },
    {
        value: "carbon-neutral-certification",
        label: "Carbon Neutral Certification"
    },
    {
        value: "eco-tourism-certification",
        label: "EcoTourism Certification"
    },
    {
        value: "green-globe-certified",
        label: "Green Globe Certified"
    },
    {
        value: "forest-restoration-certification",
        label: "Forest Restoration Certification"
    },
    {
        value: "energy-star-certified",
        label: "Energy Star Certified"
    },
    {
        value: "biodegradable-product-certification",
        label: "Biodegradable Product Certification"
    },
    {
        value: "carbon-footprint-reduction-certification",
        label: "Carbon Footprint Reduction Certification"
    }
];

export const productsOfferedMock = [
    {
        value: "outdoor-clothing",
        label: "Outdoor clothing"
    },
    {
        value: "gear",
        label: "Gear"
    },
    {
        value: "eco-friendly-packaging",
        label: "Eco-friendly Packaging"
    },
    {
        value: "renewable-energy-solutions",
        label: "Renewable Energy Solutions"
    },
    {
        value: "green-gadgets",
        label: "Green Gadgets"
    },
    {
        value: "software",
        label: "Software"
    },
    {
        value: "sustainable-footwear",
        label: "Sustainable Footwear"
    },
    {
        value: "organic-clothing",
        label: "Organic Clothing"
    },
    {
        value: "eco-friendly-building-materials",
        label: "Eco-friendly Building Materials"
    },
    {
        value: "renewable-energy-devices",
        label: "Renewable Energy Devices"
    },
    {
        value: "eco-office-supplies",
        label: "Eco Office Supplies"
    },
    {
        value: "carbon-reduction-technologies",
        label: "Carbon Reduction Technologies"
    },
    {
        value: "sustainable-travel-services",
        label: "Sustainable Travel Services"
    },
    {
        value: "reforestation-solutions",
        label: "Reforestation Solutions"
    },
    {
        value: "energy-and-water-saving-devices",
        label: "Energy and Water Saving Devices"
    },
    {
        value: "ethical-fashion",
        label: "Ethical Fashion"
    },
    {
        value: "biodegradable-packaging",
        label: "Biodegradable Packaging"
    },
    {
        value: "carbon-offsetting-services",
        label: "Carbon Offsetting Services"
    }
];

const documentTypes = [
   { value: 'license', label: 'License' },
   { value: 'contract-copy', label: 'Contract copy' },
   { value: 'invoice', label: 'Invoice' },
   { value: 'specifications', label: 'Specifications' }
]

export const industries = [
    { value: 'apparel', label: "Apparel" },
    { value: 'logistics', label: "Logistics" },
    { value: 'energy', label: "Energy" },
    { value: 'technology', label: "Technology" },
    { value: 'footwear', label: "Footwear" },
    { value: 'construction', label: "Construction" },
    { value: 'office-supplies', label: "Office Supplies" },
    { value: 'green-tech', label: "Green Tech" },
    { value: 'tourism', label: "Tourism" },
    { value: 'conservation', label: "Conservation" },
    { value: 'packaging', label: "Packaging" },
    { value: 'environmental-services', label: "Environmental Services" }
]

export const companySizes = [
    { value: '10-49 employees', label: "10-49 employees" },
    { value: '50-199 employees', label: "50-199 employees" },
    { value: '100-249 employees', label: "100-249 employees" },
    { value: '200-499 employees', label: "200-499 employees" },
    { value: '250-500 employees', label: "250-500 employees" },
    { value: '500-999 employees', label: "500-999 employees" }
  ];

  export const productCategories = [
    { value: 'fabrics', label: "Fabrics" },
    { value: 'yarns', label: "Yarns" },
    { value: 'garments', label: "Garments" },
    { value: 'accessories', label: "Accessories" },
    { value: 'home-textiles', label: "Home Textiles" },
    { value: 'toys', label: "Toys" },
    { value: 'sportswear', label: "Sportswear" },
    { value: 'underwear', label: "Underwear" },
    { value: 'socks', label: "Socks" },
    { value: 'raw-fibres', label: "Raw Fibres" },
    { value: 'hygiene-products', label: "Hygiene products" },
    { value: 'leisure-wear', label: "Leisure wear" },
    { value: 'non-wovens', label: "Non-wovens" },
    { value: 'technical-textiles', label: "Technical Textiles" },
    { value: 'product-category-other', label: "Product category (other)" },
    { value: 'ladieswear', label: "Ladieswear" },
    { value: 'babywear', label: "Babywear" },
    { value: 'mens-wear', label: "Men's wear" },
    { value: 'childrens-wear', label: "Children's wear" },
  ];

  export const productSpecs = [
    { value: 'PD0058', label: "Knitted fabrics (PD0058)" },
    { value: 'PC0025', label: "Dyed fabrics (PC0025)" },
    { value: 'PC0026', label: "Greige fabrics (PC0026)" },
    { value: 'PD0059', label: "Woven fabrics (PD0059)" },
    { value: 'PC0027', label: "Undyed Fabrics (PC0027)" },
    { value: 'RM0104', label: "100.0% Organic Cotton (RM0104)" },
    { value: 'PD0060', label: "Terry fabrics (PD0060)" },
    { value: 'RM0160', label: "5.0% Elastane (Spandex) (RM0160)" },
    { value: 'RM0104', label: "97.0% Organic Cotton (RM0104)" }
  ]

export const currencies = [{ value: 'usd', label: 'USD' }, { value: 'eur', label: 'EUR' }, { value: 'gbp', label: 'GBP' }];

export const countries_ = [
    {
        "label": "China (CN)",
        "value": "CN"
    },
    {
        "label": "United States (US)",
        "value": "USA"
    },
    {
        "label": "Italy (IT)",
        "value": "IT"
    },
    {
        "label": "Poland (PL)",
        "value": "PL"
    },
    {
        "label": "India (IN)",
        "value": "IN"
    },
    {
        "label": "France (FR)",
        "value": "FR"
    },
    {
        "label": "Turkey (TR)",
        "value": "TR"
    },
    {
        "label": "Germany (DE)",
        "value": "DE"
    },
    {
        "label": "Portugal (PT)",
        "value": "PT"
    },
    {
        "label": "Belgium (BE)",
        "value": "BE"
    },
    {
        "label": "United Kingdom (GB)",
        "value": "GB"
    },
    {
        "label": "Bangladesh (BD)",
        "value": "BD"
    },
    {
        "label": "United States (US)",
        "value": "US"
    },
    {
        "label": "Hong Kong (HK) - SAR of China",
        "value": "HK"
    },
    {
        "label": "Spain (ES)",
        "value": "ES"
    },
    {
        "label": "Denmark (DK)",
        "value": "DK"
    },
    {
        "label": "Pakistan (PK)",
        "value": "PK"
    },
    {
        "label": "Egypt (EG)",
        "value": "EG"
    },
    {
        "label": "Switzerland (CH)",
        "value": "CH"
    },
    {
        "label": "Korea, Republic of (KR)",
        "value": "KR"
    },
    {
        "label": "Austria (AT)",
        "value": "AT"
    },
    {
        "label": "Netherlands (NL)",
        "value": "NL"
    },
    {
        "label": "Viet Nam (VN)",
        "value": "VN"
    },
    {
        "label": "Peru (PE)",
        "value": "PE"
    },
    {
        "label": "Sri Lanka (LK)",
        "value": "LK"
    },
    {
        "label": "Australia (AU)",
        "value": "AU"
    },
    {
        "label": "Mauritius (MU)",
        "value": "MU"
    },
    {
        "label": "Nepal (NP)",
        "value": "NP"
    },
    {
        "label": "Greece (GR)",
        "value": "GR"
    },
    {
        "label": "Colombia (CO)",
        "value": "CO"
    },
    {
        "label": "Thailand (TH)",
        "value": "TH"
    },
    {
        "label": "Singapore (SG)",
        "value": "SG"
    },
    {
        "label": "Bulgaria (BG)",
        "value": "BG"
    },
    {
        "label": "Canada (CA)",
        "value": "CA"
    },
    {
        "label": "Tunisia (TN)",
        "value": "TN"
    },
    {
        "label": "Croatia (HR)",
        "value": "HR"
    },
    {
        "label": "United Arab Emirates (AE)",
        "value": "AE"
    },
    {
        "label": "Japan (JP)",
        "value": "JP"
    },
    {
        "label": "Marshall Islands (MH)",
        "value": "MH"
    },
    {
        "label": "Romania (RO)",
        "value": "RO"
    },
    {
        "label": "Taiwan (TW) - China",
        "value": "TW"
    },
    {
        "label": "Algeria (DZ)",
        "value": "DZ"
    },
    {
        "label": "Madagascar (MG)",
        "value": "MG"
    },
    {
        "label": "Cambodia (KH)",
        "value": "KH"
    },
    {
        "label": "Malaysia (MY)",
        "value": "MY"
    },
    {
        "label": "Czech Republic (CZ)",
        "value": "CZ"
    },
    {
        "label": "Morocco (MA)",
        "value": "MA"
    },
    {
        "label": "Indonesia (ID)",
        "value": "ID"
    },
    {
        "label": "Tanzania, United Republic of (TZ)",
        "value": "TZ"
    },
    {
        "label": "Slovenia (SI)",
        "value": "SI"
    },
    {
        "label": "Guatemala (GT)",
        "value": "GT"
    },
    {
        "label": "Lithuania (LT)",
        "value": "LT"
    },
    {
        "label": "Mexico (MX)",
        "value": "MX"
    }
];

export const requestsMock: Request[] = [
    {
      id: 101,
      name: "Laptop Procurement",
      industry: '',
      productCategory: "Electronics",
      certificationsRequired: "ISO 9001, Energy Star",
      status: "draft",
      startDate: '2024-09-01',
      dueDate: "2024-12-15",
      location: '',
      department: '',
      details: "Request for procurement of 50 high-performance laptops for the engineering department.",
      items: []
    },
    {
      id: 102,
      name: "Office Chairs Purchase",
      industry: '',
      productCategory: "Furniture",
      certificationsRequired: "BIFMA",
      status: "active",
      startDate: '2024-09-01',
      dueDate: "2024-11-30",
      details: "Procurement of ergonomic office chairs for the entire HR department.",
      items: []

    },
    {
      id: 103,
      name: "Server Upgrades",
      productCategory: "IT Equipment",
      industry: '',
      certificationsRequired: "ISO 27001",
      status: "closed",
      startDate: '2024-09-01',
      dueDate: "2024-10-01",
      details: "Upgrading servers to improve performance and enhance security protocols.",
      items: []

    }
  ];

export {
    suppliers,
    users,
    documentTypes,
    contracts
}

