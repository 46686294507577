import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { Container, Content, Title } from '../../../App';
import { TABS, URLS } from '../../../constants';
import Button from '../../../components/Button';
import { useSupplier } from '../../../context/SupplierContext';
import { Contract } from '../../../interfaces';
import { ButtonWrapper } from '../styles';
import { randomId } from '../../../utils';
import Details from '../Components/Details';
import { useNavigate } from 'react-router-dom';

const AddContract: React.FC = () => {
    const [newContract, setNewContract] = useState<Contract>({
        id: 0,
        title: '',
        reference: '',
        type: '',
        supplierId: 0,
        country: '',
        department: '',
        startDate: '',
        endDate: '',
        noticePeriodDate: '',
        currency: '',
        estimatedValue: '',
        description: '',
        items: [],
        orders: [],
        deliveries: [],
        status: 'open'
    });

    const { contracts, setContracts } = useSupplier();

    const submitContract = () => {
        const newId = randomId()
        setContracts([...contracts, { ...newContract, id: newId }]);
    };

    const navigate = useNavigate()

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.contracts} />
                
                <Content>
                    <Title>Add New Contract</Title>
                    
                    <Details
                        contract={newContract}
                        setContract={setNewContract}
                        isEditModeOn={true}
                    />
                
                    <ButtonWrapper>
                        <Button onClick={submitContract} width='200px'>Submit Contract</Button>
                        <Button onClick={() => navigate(URLS.contracts)} width='200px'>Cancel</Button>
                    </ButtonWrapper>
                </Content>
            </Container>
        </>
    );
};

export default AddContract;
