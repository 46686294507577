import React  from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { AppProvider } from '../context/AppContext';
import { SupplierProvider } from '../context/SupplierContext';
import { UserProvider, useUser } from '../context/UserContext';
import Login from '../pages/Login';
import FindSupplier from '../pages/FindSupplier/MainView';
import Signup from '../pages/Signup';
import Dashboard from '../pages/Dashboard';
import Contracts from '../pages/Contracts/MainView';
import SignupCompany from '../pages/Signup/company';
import SupplierDetails from '../pages/FindSupplier/DetailsView';
import Terms from '../pages/TermsAndConditions';
import AddSupplier from '../pages/FindSupplier/AddSupplier';
import FirstLogin from '../pages/FirstLogin';
import ForgotPassword from '../pages/ForgotPassword';
import Profile from '../pages/Profile';
import { URLS } from '../constants';
import Settings from '../pages/AdminPanel';
import AddContract from '../pages/Contracts/AddContract';
import ContractDetails from '../pages/Contracts/DetailsView';
import OrderDetails from '../pages/Contracts/OrderDetails';
import DeliveryDetails from '../pages/Contracts/DeliveryDetails';
import Requests from '../pages/Requests/MainView';
import AddRequest from '../pages/Requests/AddRequest';
import RequestDetailsView from '../pages/Requests/DetailsView';
import ApplicationDetails from '../pages/Requests/ApplicationDetails';
import AddOrder from '../pages/Contracts/AddOrder';
import AddDelivery from '../pages/Contracts/AddDelivery';

const AppRoutes: React.FC = () => {
    return (
        <Router>
            <AppProvider>
                <UserProvider>
                    <SupplierProvider>
                        <Routes>
                            <Route path={URLS.login} element={<Login />} />
                            <Route path={URLS.signup} element={<Signup />} />
                            <Route path={URLS.forgotPassword} element={<ForgotPassword />} />

                            <Route path={URLS.dashboard} element={<PrivateRoute/>}>
                                <Route path={URLS.dashboard} element={<Dashboard/>}/>
                            </Route>

                            <Route path={URLS.profile} element={<PrivateRoute/>}>
                                <Route path={URLS.profile} element={<Profile />}/>
                            </Route>

                            <Route path={URLS.settings} element={<PrivateRoute/>}>
                                <Route path={URLS.settings} element={<Settings />}/>
                            </Route>

                            <Route path={URLS.contracts} element={<PrivateRoute/>}>
                                <Route path={URLS.contracts} element={<Contracts/>}/>
                            </Route>

                            <Route path={URLS.addContract} element={<PrivateRoute/>}>
                                <Route path={URLS.addContract} element={<AddContract/>}/>
                            </Route>

                            <Route path={URLS.contractDetails} element={<PrivateRoute/>}>
                                <Route path={URLS.contractDetails} element={<ContractDetails />}/>
                            </Route>

                            <Route path={URLS.orderDetails} element={<PrivateRoute/>}>
                                <Route path={URLS.orderDetails} element={<OrderDetails/>}/>
                            </Route>

                            <Route path={URLS.deliveryDetails} element={<PrivateRoute/>}>
                                <Route path={URLS.deliveryDetails} element={<DeliveryDetails/>}/>
                            </Route>

                            <Route path={URLS.addOrder} element={<PrivateRoute/>}>
                                <Route path={URLS.addOrder} element={<AddOrder/>}/>
                            </Route>

                            <Route path={URLS.addDelivery} element={<PrivateRoute/>}>
                                <Route path={URLS.addDelivery} element={<AddDelivery/>}/>
                            </Route>

                            <Route path={URLS.requests} element={<PrivateRoute/>}>
                                <Route path={URLS.requests} element={<Requests />}/>
                            </Route>

                            <Route path={URLS.addRequests} element={<PrivateRoute/>}>
                                <Route path={URLS.addRequests} element={<AddRequest/>}/>
                            </Route>

                            <Route path={URLS.requestDetails} element={<PrivateRoute/>}>
                                <Route path={URLS.requestDetails} element={<RequestDetailsView/>}/>
                            </Route>

                            <Route path={URLS.applicationDetails} element={<PrivateRoute/>}>
                                <Route path={URLS.applicationDetails} element={<ApplicationDetails/>}/>
                            </Route>

                            <Route path={URLS.signupCompany} element={<PrivateRoute/>}>
                                <Route path={URLS.signupCompany} element={<SignupCompany />}/>
                            </Route>

                            <Route path={URLS.firstLogin} element={<PrivateRoute/>}>
                                <Route path={URLS.firstLogin} element={<FirstLogin />}/>
                            </Route>

                            <Route path={URLS.home} element={<Navigate to={URLS.login} />} />

                            <Route path={URLS.terms} element={<Terms />} />
                    
                            <Route path={URLS.suppliers} element={<PrivateRoute/>}>
                                <Route path={URLS.suppliers} element={<FindSupplier/>}/>
                            </Route>

                            <Route path={URLS.supplierDetails} element={<PrivateRoute/>}>
                                <Route path={URLS.supplierDetails} element={<SupplierDetails/>}/>
                            </Route>

                            <Route path={URLS.addSupplier} element={<PrivateRoute/>}>
                                <Route path={URLS.addSupplier} element={<AddSupplier/>}/>
                            </Route>
                        </Routes>

                    </SupplierProvider>
                </UserProvider>
            </AppProvider>

          
        </Router>
                    
    );
};

const PrivateRoute = () => {
    const { user } = useUser();

    return user ? <Outlet /> : <Navigate to={URLS.login} />;
}

export default AppRoutes;