import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebar from '../../../components/Sidebar';
import { colors, inputBorderRadius, TABS, URLS } from '../../../constants';
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import RoundButton from '../../../components/RoundButton';
import { useNavigate } from 'react-router-dom';
import { useSupplier } from '../../../context/SupplierContext';
import { Request } from '../../../interfaces';

const Requests: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const { requests } = useSupplier();

  const columns = [
    { field: 'id', filter: true },
    { field: 'name', filter: true },
    { field: 'productCategory', filter: true },
    { field: 'certificationsRequired', filter: true },
    { field: 'status', filter: true },
    { field: 'dueDate', filter: true },
    { field: 'details', filter: true },
  ]
  
  // Filter contracts based on the search term
  const filteredRequests = requests
    .filter((request) =>
      columns.some((column) =>
        request[column.field as keyof typeof request]
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );

  const handleClickOnRow = (request: Request) => {
    navigate(URLS.requestDetails, { state: { requestId: request.id } });
    console.log({ request })
  };

  return (
    <>
      <Header />
      <Container>
        <Sidebar tab={TABS.requests} />
        
        <Content>
          <Title>Requests</Title>
          <SearchBarWrapper>
            <SearchInput
              type="text"
              placeholder="Search requests..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchBarWrapper>
          <Table height={500} columns={columns} rowData={filteredRequests} onRowClick={(row) => handleClickOnRow(row.data)} />
          <RoundButtonWrapper>
            <RoundButton onClick={() => navigate(URLS.addRequests)} text='Add new request' size={60}/>
          </RoundButtonWrapper>
        </Content>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 60px);
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
`;

const Title = styled.h2`
  text-align: center;
  color: ${colors.darkGreen};
  margin-bottom: 20px;
  margin-top: 0;
`;

const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`;

const SearchInput = styled.input`
  padding: 10px;
  font-size: 14px;
  width: 100%;
  border: 1px solid ${colors.lightGreen};
  border-radius: ${inputBorderRadius};
`;

const RoundButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export default Requests;
