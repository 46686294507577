import React from 'react';
import { borderRadius, colors } from '../../constants';
import styled from 'styled-components';

const StyledButton = styled.button<{ width?: string | number; color?: string}>`
  padding: 12px;
  width: ${({ width }) => width || '100%' };
  font-size: 16px;
  background-color: ${({ color }) => color || colors.darkGreen}; 
  color: white;
  border: none;
  border-radius: ${borderRadius.button};
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${colors.darkGreenHover};
  }

  &:active {
    background-color: ${colors.darkGreenHover};
  }
`;

interface ButtonProps {
    onClick?: (e: any) => void;
    children?: React.ReactNode;
    type?: "button" | "submit" | "reset";
    light?: boolean;
    width?: number | string;
    color?: string;
}

const Button: React.FC<ButtonProps> = ({ children, type, onClick, width, color }) => {
    return (
        <StyledButton type={type} color={color} onClick={onClick} width={width}>{ children }</StyledButton>
    );
};

export default Button;