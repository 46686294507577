import styled from "styled-components";
import { colors } from "../../constants";

export const Flex = styled.div`
    display: flex;
    gap: 30px;
    align-items: baseline;
    padding-bottom: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const GeneralInfo = styled.div`
    flex-basis: 45%;
    @media (max-width: 768px) {
        flex-basis: 100%;
        width: 100%;
    }
`;

export const OperationalDetail = styled.div`
    flex-basis: 55%;
    @media (max-width: 768px) {
        flex-basis: 100%;
        width: 100%;
    }
`;

export const TableBox = styled.div`
    border: 1px solid ${colors.tableBorder};
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`;

export const InputWrapper = styled.div`
    display: flex;
    gap: 10px;
    font-size: 13px;
    align-items: center;
    margin-bottom: 10px;

    > label {
        width: 150px;
    }
    > div {
        width: 100%;
        margin-bottom: 0px;
    }
`;

export const Label = styled.div`
     font-size: 14px;
     font-weight: 500;
     margin-bottom: 5px;
     color: ${colors.darkGreen};
`

export const DescriptionWrapper = styled.div`
    padding-top: 20px;
    width: 100%;
    font-size: 13px;
`;

export const AddItemContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`

export const ButtonWrapper = styled.div`
    padding-top: 30px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 20px;
`;
