import React, { useState } from 'react';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';

interface SendToSupplierProp {
    send: (data: {
        to: string,
        from: string,
        subject: string,
        content: string,
    }) => void;
    cancel: () => void
}
const SendToSupplier: React.FC<SendToSupplierProp> = ({ send, cancel }) => {
    const [to, setTo] = useState('')
    const [from, setFrom] = useState('')
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState('')
    
    return (
        <>
            <Input label="To" value={to} name="to" onChange={(value) => setTo(value)}></Input>
            <Input label="From" value={from} name="from" onChange={(value) => setFrom(value)}></Input>
            <Input label="Subject" value={subject} name="subject" onChange={(value) => setSubject(value)}></Input>
            <Input label="Content" textarea value={content} name="from" onChange={(value) => setContent(value)}></Input>
            <div style={{ display: 'flex', gap: '20px' }}>
                <Button onClick={() => send({ to, from, subject, content})}>Send</Button>
                <Button onClick={cancel}>Cancel</Button>
            </div>
        </>
    )
}

export default SendToSupplier;
