import React from 'react';
import Table from '../../../../components/Table';
import RoundButton from '../../../../components/RoundButton';
import { RoundButtonContainer } from '../../../AdminPanel';
import { ordersColumn } from '../../config';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../../constants';
import { Contract, Order } from '../../../../interfaces';

const PO: React.FC<{ contract: Contract }> = ({ contract }) => {    
    const navigate = useNavigate()

    const handleClickOnRow = (order: Order) => {
        navigate(URLS.orderDetails, { state: { orderId: order.poNumber, contractId: contract.id } });
      };

    return (
        <>          
            <Table columns={ordersColumn} rowData={contract.orders || []} height={300} onRowClick={(row) => handleClickOnRow(row.data)} />
            <RoundButtonContainer>
                <RoundButton text='Create new order' onClick={() => navigate(URLS.addOrder, { state: { contractId: contract.id }})}/>
            </RoundButtonContainer>
        </>
    );
};

export default PO;
