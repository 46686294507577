import React from 'react';
import { TableBox } from '../styles';
import { applications } from '../../../mocks';
import { useLocation } from 'react-router-dom';
import { Container } from '../../../App';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import { colors, TABS } from '../../../constants';
import { Title } from '../../AdminPanel';
import { Content } from '../../Profile';
import styled from 'styled-components';
import Button from '../../../components/Button';

const Name = styled.span`
    color: grey;
`;

const ApplicationDetails: React.FC = () => {
    const location = useLocation();
    const state = location.state;

    const { appId } = state;

    const appIndex = applications.findIndex((app) => app.id === appId) || 0
    const application = applications[appIndex]
    
    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.requests} />
                
                <Content>
                    <Title>Application {appId}</Title>
                     <div style={{ display: 'flex', gap: '20px' }}>
                <TableBox>
                    <div><Name>Supplier name:</Name> {application.supplierName}</div>
                    <div><Name>Industry:</Name> </div>
                    <div><Name>Product categories:</Name> </div>
                    <div><Name>Operations details:</Name> </div>
                    <div><Name>Material used:</Name> </div>
                    <div><Name>Ethical standard:</Name> </div>
                    <div><Name>Capacity range:</Name> </div>
                    <div><Name>Certifications require:</Name> </div>
                    <div><Name>Start date:</Name> </div>
                    <div><Name>End date:</Name> </div>
                    <div><Name>Location:</Name> </div>
                    <div><Name>Department:</Name> </div>
                    <div><Name>Description:</Name> </div>

                </TableBox>
                </div>
                <div style={{ marginTop: 20, display: 'flex', gap: 10 }}>
                        <Button width='150px' color={colors.green}>Accept offer</Button>
                        <Button width='150px' color={colors.red}>Reject offer</Button>
                        <Button width='150px'>Compare offers</Button>
                    </div>
            </Content>
        </Container>
                
        </>
    )
}

export default ApplicationDetails;