import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { Container, Content, Title } from '../../../App';
import { colors, TABS } from '../../../constants';
import styled from 'styled-components';
import Table from '../../../components/Table';
import { contractItemsColumns } from '../config';
import { useSupplier } from '../../../context/SupplierContext';
import { suppliers } from '../../../mocks';

const TableBox = styled.div`
    border: 1px solid ${colors.tableBorder};
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;


const Name = styled.span`
    color: grey;
`;

const DeliveryDetails: React.FC = () => {
    const location = useLocation();
    const state = location.state;

    const { deliveryId, contractId } = state;

    const { contracts } = useSupplier() || []
    const { deliveries = []} = contracts.find(contract => contract.id === contractId ) || {}
    const contract = contracts.find(c => c.id === contractId)
    const deliveryIndex = deliveries.findIndex((del: any) => del.id === deliveryId)
    const delivery = deliveries[deliveryIndex]

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.contracts} />
                
                <Content>
                    <Title>Delivery { delivery?.id }</Title>

                    <TableBox>
                        <p><Name>Buyer Name:</Name> {delivery.buyerName}</p>
                        <p><Name>Buyer address:</Name> {delivery.buyerAddress}</p>
                        <p><Name>Supplier name:</Name> {suppliers.find(sup => sup.id === contract?.supplierId)?.company}</p>
                        <p><Name>Delivered on:</Name> {delivery.deliveryDate}</p>
                        <p><Name>Received on:</Name> {delivery.receivedOn}</p>
                        <p><Name>Delivery address:</Name> {delivery.address}</p>
                        <p><Name>Delivery via:</Name> {delivery.deliveriedVia}</p>
                    </TableBox>

                    <div style={{ padding: '20px 0'}}>
                        <Table rowData={delivery.items || []} columns={contractItemsColumns(false)} height={300} />
                    </div>
                </Content>
            </Container>
        </>
    );
};

export default DeliveryDetails;