import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { colors, inputBorderRadius } from '../../constants';
import { GoTriangleDown } from "react-icons/go";

const FormGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${colors.darkGreen};
`;

const MultiSelectContainer = styled.div<{ disabled?: boolean }>`
  border: 1px solid ${({ disabled }) => (disabled ? '#e0e0e0' : colors.mediumGreen)};
  border-radius: 15px;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  padding: 7px 10px;
  min-height: 37px;
  background-color: ${({ disabled }) => (disabled ? colors.lightGrey : 'white')};
`;

const SelectedItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 13px;
  color: grey;
  line-height: 1.6;
`;

const SelectedItem = styled.div`
  background-color: ${colors.mediumGrey};
  padding: 1px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 5px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const OptionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 15px;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  z-index: 20;
`;

const OptionItem = styled.li`
  padding: 5px 10px;
  cursor: pointer;
  font-size: 15px;
  color: ${colors.darkGreen};
  label {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
  }
`;

const RemoveIcon = styled(FaTimes)`
  cursor: pointer;
  color: ${colors.darkOrange};
`;

const SearchInput = styled.input`
  width: 90%;
  padding: 7px;

  margin: 10px;
  border: 1px solid ${colors.mediumGreen};
  border-radius: ${inputBorderRadius};

  &:focus {
    outline: none;
  }
`;

const Triangle = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

interface MultiSelectProps {
  options: { value: string; label: string }[];
  onChange?: (selected: string[]) => void;
  name: string;
  label?: string;
  disabled?: boolean;
  selectedValues?: string[];
  placeholder?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ placeholder, options, onChange, name, label, disabled, selectedValues }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(selectedValues || []);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);
  const removeIconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedOptions(selectedValues || []);
  }, [selectedValues]);

  const toggleOption = (value: string) => {
    const updatedSelection = selectedOptions.includes(value)
      ? selectedOptions.filter((optionId) => optionId !== value)
      : [...selectedOptions, value];

    setSelectedOptions(updatedSelection);
    if (onChange) onChange(updatedSelection);
  };

  const removeOption = (value: string) => {
    const updatedSelection = selectedOptions.filter((optionId) => optionId !== value);
    setSelectedOptions(updatedSelection);
    if (onChange) onChange(updatedSelection);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleClick = () => {
    if (disabled) return;
      setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <FormGroup>
      { label && <Label htmlFor={name}>{label}</Label> }
      <MultiSelectContainer ref={containerRef} onClick={handleClick} disabled={disabled}>
        <SelectedItems>
          { placeholder && !selectedOptions.length &&
            <>{placeholder}</>
          }
          { !selectedOptions.length && <Triangle><GoTriangleDown /></Triangle> }
          {selectedOptions.map((value) => {
            const option = options.find((opt) => opt.value === value);
            return (
              <SelectedItem key={value}>
                {option?.label}
                {!disabled && <div ref={removeIconRef} onClick={(e) => {
                  e.stopPropagation(); 
                  if (!disabled) {
                    removeOption(value)
                  }
                }}><RemoveIcon/></div>}
              </SelectedItem>
            );
          })}
        </SelectedItems>

        {isOpen && (
          <OptionList onClick={(e) => { e.stopPropagation() }}>
            <SearchInput
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <OptionItem key={option.value}><label >
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option.value)}
                    onChange={() => toggleOption(option.value)}
                  />
                    {option.label}
                </label>
                </OptionItem>
              ))
            ) : (
              <OptionItem>No options found</OptionItem>
            )}
          </OptionList>
        )}
      </MultiSelectContainer>
    </FormGroup>
  );
};

export default MultiSelect;
