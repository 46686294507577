import React, { useState } from 'react';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { certificationsMock, countries_, currencies, productCategories } from '../../../../mocks';
import { useSupplier } from '../../../../context/SupplierContext';
import Select from '../../../../components/Select';
import { Contract, Item } from '../../../../interfaces';
import Table from '../../../../components/Table';
import MultiSelect from '../../../../components/Multiselect';
import { Flex, GeneralInfo, TableBox, InputWrapper, OperationalDetail, DescriptionWrapper, Label, AddItemContainer } from '../../styles';
import { randomId } from '../../../../utils';
import { contractItemsColumns } from '../../config';
import RoundButton from '../../../../components/RoundButton';
import ProgressBar from '../ProgressBar';

interface DetailsProp {
    contract: Contract;
    setContract: (contract: Contract) => void;
    isEditModeOn?: boolean;
    showProgress?: boolean;
}
const Details: React.FC<DetailsProp> = ({ contract, showProgress, setContract, isEditModeOn }) => {
    const { suppliers, departments } = useSupplier();

    const [addItemMode, setAddItemMode] = useState(false);

    const handleInputChange = (field: string, value: string | number) => {
        setContract({ ...contract, [field]: value });
    };

    const handleInputItemChange = (field: string, value: string | number) => {
        setNewItem({ ...newItem, [field]: value });
    };

    const itemDefault = {
        id: 0,
        productCategory: '',
        material: '',
        ethicalStandard: '',
        capacityRange: '',
        certifications: '',
        quantity: 0,
        details: '',
        unitPrice: 0,
        subtotal: 0
    }

    const [newItem, setNewItem] = useState<Item>(itemDefault)

    const saveItem = () => {
        const newId = randomId()
        const items = [...contract.items, { ...newItem, id: newId, subtotal: newItem.unitPrice * newItem.quantity }]
        setContract({ ...contract, items })
        setNewItem(itemDefault)
        setAddItemMode(false)

    }

    const cancelItem = () => {
        setAddItemMode(false)

        setNewItem(itemDefault)
    }

    return (
        <>          
        <Flex>
            <GeneralInfo>
                <TableBox>
                    <InputWrapper>
                        <label htmlFor='title'>Contract Title</label>
                        <Input
                            value={contract.title}
                            type='text'
                            name='title'
                            onChange={(value) => handleInputChange('title', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>
                    
                    <InputWrapper>
                        <label htmlFor='reference'>Contract Reference</label>
                        <Input
                            value={contract.reference}
                            type='text'
                            name='reference'
                            onChange={(value) => handleInputChange('reference', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='type'>Contract Type</label>
                        <Select
                            options={[{ label: 'express', value: 'express'}, { label: 'fixed-price', value: 'fixed-price'}]}
                            name='type'
                            value={contract.type}
                            onChange={(e) => handleInputChange('type', e.target.value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='supplier'>Supplier</label>
                        <Select
                            options={suppliers?.map(supplier => ({ label: supplier.company, value: supplier.id.toString() })) || []}
                            name='supplier'
                            value={contract.supplierId.toString()}
                            onChange={(e) => handleInputChange('supplierId', Number(e.target.value))}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='country'>Country</label>
                        <Select
                            options={countries_}
                            name='country'
                            value={contract.country}
                            onChange={(e) => handleInputChange('country', e.target.value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='department'>Department</label>
                        <Select
                            options={departments?.map(dep => ({ value: dep.name, label: dep.name })) || []}
                            name='department'
                            value={contract.department}
                            onChange={(e) => handleInputChange('department', e.target.value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>
                </TableBox>
            </GeneralInfo>
            <OperationalDetail>
                <TableBox>
                    <InputWrapper>
                        <label htmlFor='startDate'>Start Date</label>
                        <Input
                            value={contract.startDate}
                            type='date'
                            name='startDate'
                            onChange={(value) => handleInputChange('startDate', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='endDate'>End Date</label>
                        <Input
                            value={contract.endDate}
                            type='date'
                            name='endDate'
                            onChange={(value) => handleInputChange('endDate', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='noticePeriodDate'>Notice Period Date</label>
                        <Input
                            value={contract.noticePeriodDate}
                            type='date'
                            name='noticePeriodDate'
                            onChange={(value) => handleInputChange('noticePeriodDate', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='currency'>Currency</label>
                        <Select
                            options={currencies}
                            name='currency'
                            value={contract.currency}
                            onChange={(e) => handleInputChange('currency', e.target.value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <label htmlFor='estimatedValue'>Estimated Contract Value</label>
                        <Input
                            value={contract.estimatedValue}
                            name='estimatedValue'
                            onChange={(value) => handleInputChange('estimatedValue', value)}
                            disabled={!isEditModeOn}
                        />
                    </InputWrapper>
                </TableBox>
            </OperationalDetail>
        </Flex>

        {showProgress &&
            <TableBox>
                <ProgressBar isActive={true} />
            </TableBox>
        }

        <DescriptionWrapper>
            <label htmlFor='description'>Contract Description</label>
            <Input
                value={contract.description}
                textarea
                name='description'
                onChange={(value) => handleInputChange('description', value)}
                style={{ width: '100%' }}
                disabled={!isEditModeOn}
            />
        </DescriptionWrapper>
        
        <div style={{ marginTop: '20px' }}>
            <Label>Contract items</Label>
            <div style={{ paddingBottom: '20px'}}>
            <Table rowData={contract.items || []} columns={contractItemsColumns(false)} height={300} />
            </div>
            { !addItemMode && isEditModeOn &&
                <AddItemContainer>
                    <RoundButton onClick={() => setAddItemMode(true)} text="Add item" /> 
                </AddItemContainer>
            }
        </div>

        { addItemMode && isEditModeOn && <TableBox>
        <Label>New item</Label>

        <Flex>
            <GeneralInfo>
                <InputWrapper>
                    <label htmlFor='productCategory'>Product Category</label>
                    <Select
                        value={newItem.productCategory}
                        options={productCategories}
                        name='productCategory'
                        onChange={(e) => handleInputItemChange('productCategory', e.target.value)}
                        disabled={!isEditModeOn}
                    />
                </InputWrapper>

                <InputWrapper>
                    <label htmlFor='material'>Material used</label>
                    <MultiSelect
                        selectedValues={newItem.material ? newItem.material.split(',') : []}
                        options={[ { label: 'material1', value: 'material1' }]}
                        name='material'
                        onChange={(values) => handleInputItemChange('material', values.join(','))}
                        disabled={!isEditModeOn}
                    />
                </InputWrapper>

                <InputWrapper>
                    <label htmlFor='ethicalStandard'>Ethical standard</label>
                    <MultiSelect
                        selectedValues={newItem.ethicalStandard ? newItem.ethicalStandard.split(',') : []}
                        options={[ { label: 'standard1', value: 'standard1' }]}
                        name='ethicalStandard'
                        onChange={(values) => handleInputItemChange('ethicalStandard', values.join(','))}
                        disabled={!isEditModeOn}
                    />
                </InputWrapper>

                <InputWrapper>
                    <label htmlFor='capacityRange'>Capacity range</label>
                    <Select
                        value={newItem.capacityRange}
                        options={[ { label: 'range1', value: 'range1' }]}
                        name='capacityRange'
                        onChange={(e) => handleInputItemChange('capacityRange', e.target.value)}
                        disabled={!isEditModeOn}
                    />
                </InputWrapper>

                <InputWrapper>
                    <label htmlFor='certifications'>Certifications</label>
                    <MultiSelect
                        name="certifications"
                        selectedValues={newItem.certifications ? newItem.certifications.split(',') : []}
                        options={certificationsMock}
                        onChange={(values) => handleInputItemChange('certifications', values.join(','))}
                        disabled={!isEditModeOn}
                    />
                </InputWrapper>
                </GeneralInfo>
                
                <GeneralInfo>
                <InputWrapper>
                    <label htmlFor='quantity'>Quantity</label>
                    <Input
                        value={newItem.quantity}
                        type="number"
                        name='quantity'
                        onChange={(value) => handleInputItemChange('quantity', value)}
                        disabled={!isEditModeOn}
                    />
                </InputWrapper>

                <InputWrapper>
                    <label htmlFor='unitPrice'>Unit price</label>
                    <Input
                        value={newItem.unitPrice}
                        type="number"
                        name='unitPrice'
                        onChange={(value) => handleInputItemChange('unitPrice', value)}
                        disabled={!isEditModeOn}
                    />
                </InputWrapper>

                <InputWrapper>
                    <label htmlFor='details'>Details</label>
                    <Input
                        textarea
                        value={newItem.details}
                        name='details'
                        onChange={(value) => handleInputItemChange('details', value)}
                        disabled={!isEditModeOn}
                    />
                </InputWrapper>

                </GeneralInfo>
                </Flex>

                <div style={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                    <Button onClick={saveItem}  width='100px'>Save item</Button>
                    <Button onClick={cancelItem} width='100px'>Cancel</Button>
                    
                </div>
            </TableBox>
}
            
            <div style={{ paddingTop: '30px', width: '400px' }}>
                <Label>
                    <label htmlFor='attachment'>Attach supporting document</label>
                </Label>
                <Input name='attachment' type="file" />
            </div>
        </>
    );
};

export default Details;
