import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { Container, Content, Title } from '../../../App';
import styled from 'styled-components';
import { colors, TABS } from '../../../constants';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import RoundButton from '../../../components/RoundButton';
import Modal from '../../../components/Modal';
import AddNewDocumentModal from '../AddNewDocumentModal';
import { Supplier, SupplierDocument}  from '../../../interfaces';
import { documentTypes, industries, productCategories, productSpecs } from '../../../mocks';
import { useUser } from '../../../context/UserContext';
import Table from '../../../components/Table';
import MultiSelect from '../../../components/Multiselect';
import { useSupplier } from '../../../context/SupplierContext';
import Select from '../../../components/Select';

const AddSupplier: React.FC = () => {
    const [adminEmails, setAdminEmails] = useState<string[]>(['']);
    const [ newSupplier, setNewSupplier ] = useState<Supplier>({
        company: '',
        country: '',
        product_categories: '',
        product_spec: '',
        id: 0,
        industry: '',
        documents: [],
    });

    const { user }  = useUser();
    const { suppliers, setSuppliers } = useSupplier();

    const documentsColumns = [
        {
            field: 'name',
            filter: true,
        },
        {
            field: 'type',
            filter: true,
        },
        {
            field: 'expiry',
            filter: true,
        }
    ]

    const addEmailInput = () => {
       setAdminEmails([...adminEmails, '']);
    }
    const addAdminEmail = (value: string, index: number) => {
        const emails = [...adminEmails];
        emails[index] = value;
        setAdminEmails(emails);
    }

    const [documentsModalIsOpened, setDocumentsModalIsOpened] = useState(false);

    const [sumbittedModalIsOpened, setSubmittedModalIsOpened] = useState(false);

    const onSaveDocument = (document: SupplierDocument) => {
        const document_ = { ... document, type: documentTypes.find((type) => type.value === document.type)?.label || document.type }
        setNewSupplier({...newSupplier, documents: [...newSupplier?.documents || [], document_]})

        setDocumentsModalIsOpened(false);
    }

    const submitSupplier = () => {
        const newId = Math.max(...suppliers.map(sup => sup.id)) + 1
        setSuppliers([...suppliers, { ...newSupplier, id: newId}]);

        console.log({ newSupplier })
        console.log({ adminEmails })
        
        setSubmittedModalIsOpened(true)
        setNewSupplier({
            company: '',
            country: '',
            product_categories: '',
            product_spec: '',
            id: 0,
            industry: '',
            documents: [],
        });
    }

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.suppliers} />
                
                <Content>
                    <Title>Add new supplier</Title>
                    
                    <Flex>
                        <GeneralInfo>
                            <BlockTitle>General Info</BlockTitle>
                            <TableBox>
                                <InputWrapper>
                                    <label htmlFor='company'>Supplier Name</label>
                                    <Input
                                        value={newSupplier?.company || ''}
                                        type='text'
                                        name='supplierName'
                                        onChange={(value) => setNewSupplier({...newSupplier, company: value})}   
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label htmlFor='country'>Supplier country</label>
                                    <Input
                                        value={newSupplier?.country || ''}
                                        type='text'
                                        name='country'
                                        onChange={(value) => setNewSupplier({...newSupplier, country: value})}   
                                    />
                                </InputWrapper>
                                <InputWrapper>
                                    <label htmlFor='industry'>Supplier industry</label>
                                    <Select
                                        value={newSupplier?.industry || ''}
                                        name='industry'
                                        options={industries}
                                        onChange={(e) => setNewSupplier({...newSupplier, industry: e.target.value})}   
                                    />
                                </InputWrapper>
                            </TableBox>
                        </GeneralInfo>
                        <OperationalDetail>
                            <BlockTitle>Operational Detail</BlockTitle>
                            <TableBox>
                                <InputWrapper>
                                    <label htmlFor='productCategories'>Supplier Product categories</label>
                                    <MultiSelect
                                        options={productCategories}
                                        name='productCategories'
                                        selectedValues={newSupplier?.product_categories ? newSupplier.product_categories.split(', ') : []}
                                        onChange={(values) => {
                                            setNewSupplier({...newSupplier, product_categories: values.join(', ')})
                                        }}    
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label htmlFor='productSpecs'>Supplier Product specs</label>
                                    <MultiSelect
                                        options={productSpecs}
                                        name='productSpecs'
                                        selectedValues={newSupplier?.product_spec ? newSupplier.product_spec.split(', ') : []}
                                        onChange={(values) => {
                                            setNewSupplier({...newSupplier, product_spec: values.join(', ')})
                                        }}    
                                    />
                                </InputWrapper>
                            </TableBox>
                        </OperationalDetail>
                    </Flex>
                    <SupplierDocuments>
                        <BlockTitle>Supplier Documents</BlockTitle>
                        <DocumentsTableWrapper>
                            <TableWrapper>
                                <Table
                                    height={200}
                                    rowData={newSupplier?.documents || []}
                                    columns={documentsColumns}
                                />
                            </TableWrapper>
                            <RoundButton text='Add document' onClick={() => setDocumentsModalIsOpened(true)} size={35}/>
                        </DocumentsTableWrapper>
                    </SupplierDocuments>

                    <AdminEmails>
                        <Inputs><BlockTitle>Invite supplier admin(s)</BlockTitle>
                        { adminEmails.map((email, index) => <InputWrapper key={index}>
                            <label htmlFor='adminEmail'>Email address</label>
                            <Input
                                value={email}
                                type='text'
                                name='adminEmail'
                                onChange={(value) => addAdminEmail(value, index)}   
                            />
                        </InputWrapper> )}
                        </Inputs>
                        <AddEmailButtonWrapper>
                            <RoundButton onClick={addEmailInput} size={35}/>
                        </AddEmailButtonWrapper>
                    </AdminEmails>
                    
                    <ButtonWrapper>
                        <Button onClick={submitSupplier}>Submit Supplier</Button>
                    </ButtonWrapper>
                </Content>
            </Container>

            <Modal
                isOpen={documentsModalIsOpened}
                onClose={() => setDocumentsModalIsOpened(false)}
            >
                <AddNewDocumentModal onSave={(document) => onSaveDocument(document)}/>
            </Modal>

            <Modal
                isOpen={sumbittedModalIsOpened}
                onClose={() => setSubmittedModalIsOpened(false)}
            >
                <>
                    <h3 style={{ marginBottom: 10 }}>Thank you, {user?.firstname || ''}!</h3>
                    <p>The supplier profile is successfully submitted, the admin will receive a notification now to activate his profile and start using the platform</p>
                </>
            </Modal>
        </>
    );
};

export default AddSupplier;

const Flex = styled.div`
    display: flex;
    gap: 20px;
    align-items: baseline;
    padding-bottom: 30px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const GeneralInfo = styled.div`
    flex-basis: 45%;
    @media (max-width: 768px) {
        flex-basis: 100%;
        width: 100%;
    }
`;

const OperationalDetail = styled.div`
    flex-basis: 55%;
    @media (max-width: 768px) {
        flex-basis: 100%;
        width: 100%;
    }
`;

const BlockTitle = styled.h3`
    margin-bottom: 20px;
`;

const SupplierDocuments = styled.div`
       padding-bottom: 30px;
`;

const TableBox = styled.div`
    border: 1px solid ${colors.tableBorder};
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`;

const Inputs = styled.div`
    flex-basis: 90%;
`;

const InputWrapper = styled.div`
    display: flex;
    gap: 10px;
    font-size: 13px;
    align-items: center;
    margin-bottom: 10px;

    > label {
        width: 150px;
    }
    > div {
        width: 100%;
        margin-bottom: 0px;
    }
`;

const ButtonWrapper = styled.div`
    padding-top: 30px;;
    width: 200px;
    margin: 0 auto;
`;

const AdminEmails = styled.div`
    max-width: 450px;
    display: flex;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const AddEmailButtonWrapper = styled.div`
    margin-left: 20px;
    margin-top: auto;
    flex-basis: 10%;
    margin-bottom: 10px;
`;

const DocumentsTableWrapper = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableWrapper = styled.div`
    width: 100%;
`;