import React from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

interface SendToSupplierProp {
    cancel: () => void
}
const SendToSupplier: React.FC<SendToSupplierProp> = ({  cancel }) => {
    return (
        <>
            <h3>Request is edited</h3>
            <p style={{ paddingTop: 10, paddingBottom: 10 }}>Please describe reason for editing and tell us if you want to send a note
            to the suppliers or no?</p>
            <Input
                textarea
                name="reason"
            />
             <div style={{ display: 'flex', gap: '20px' }}>
                <Button onClick={cancel}>Send note to suppliers</Button>
                <Button onClick={cancel}>No need to send a note</Button>
            </div>
        </>
    )
}

export default SendToSupplier;
