import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';

interface RadioInputProps {
  name: string;
  options: { label: string; value: string | number }[];
  value?: string | number;
  onChange?: (value: string | number) => void;
  error?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const StyledRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRadioLabel = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) => (disabled ? colors.lightGrey : colors.darkGreen)};
`;

const StyledRadioInput = styled.input`
  margin-right: 10px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

const Error = styled.div`
  color: red;
  margin-top: 5px;
`;

const RadioInput: React.FC<RadioInputProps> = ({
  name,
  options,
  value,
  onChange,
  error,
  disabled,
  style,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value);
  };

  return (
    <StyledRadioWrapper style={style}>
      {options.map((option) => (
        <StyledRadioLabel key={option.value} disabled={disabled}>
          <StyledRadioInput
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={handleChange}
            disabled={disabled}
          />
          {option.label}
        </StyledRadioLabel>
      ))}
      {error && <Error>{error}</Error>}
    </StyledRadioWrapper>
  );
};

export default RadioInput;
